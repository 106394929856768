body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.pre-loader{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    text-align: center;
    vertical-align: middle;
}
.loader{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0px;
    text-align: center;
    vertical-align: middle;
}
.pre-loader .fa{
    z-index: 9999999;
    color: #000;
    font-size: 2rem;
    text-align: center;
    align-items: unset;
    position: absolute;
    top: 50vh;
}
.overflow-hidden{ overflow: hidden;}
.notfound-page {
    width: 100%;
    float: left;
    background-color: #1a69ae;
    padding: 60px 15px;
    text-align: center;
    height: 600px;
}
.notfound-page h1 {
    color: #eaff72;
    font-size: 200px;
    letter-spacing: 10px;
}
.notfound-page h5 {
    color: #fff;
    font-size: 38px;
    font-weight: normal;
    letter-spacing: 3px;
}
.notfound-page p {
    font-size: 15px;
    color: #fff;
    width: 80%;
    margin: 15px auto 20px;
    line-height: 22px;
}
.buttons {
    margin-top: 40px;
}
.buttons a {
    color: #ffffff;
    text-decoration: none;
    background-color: #3084ce;
    padding: 10px 15px;
    font-size: 18px;
    border-radius: 4px;
    margin: 10px 5px;
    display: inline-block;
    transition: 0.5s;
}
.buttons a:hover{
  background-color: #3599f1;
}
.buttons a i {
    margin-right: 5px;
}
.search-box {
    position: relative;
    width: 80%;
    margin: 50px auto 0px;
}
.search-box input {
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    font-size: 17px;
    border: none;
}
.search-box button {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 40px;
    border: none;
    background-color: #3084ce;
    color: #fff;
    font-size: 16px;
    padding: 0px 15px;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.5s;
}
.search-box button:hover{
  background-color: #3599f1;
}

/*--Responsive CSS For Small Devices--*/
@media (max-width: 1200px) {
.notfound-page h1 {
    font-size: 100px;
}
.wrapper {
    width: 90%;
}
.notfound-page p {
    width: 100%;
}
.notfound-page h5 {
    font-size: 18px;
}
.buttons a {
    padding: 8px 8px;
    font-size: 15px;
}
.search-box {
    width: 100%;
}
}
.no-padding{
    padding: 0px!important;
    max-width: unset!important;
    width:90%!important;
}
.invalid-auth-title{font-size: 5rem!important;margin-bottom: 10%;}
.header-bottom .left-navigation a.btn, .header-bottom .left-navigation .nav-link{ background-color: #797A76; color: white !important;font-weight: 400;}
.cursor-pointer{cursor: pointer;}
.form-select{ white-space: normal;}