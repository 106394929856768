
// Sen font- alternative of Avenir
// Lato Regular and Bold
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Sen&display=swap');
// Tabs styles
.wow-app {
    #wowTab {
        background-color: $white;
        border-bottom: solid 1px $tertiary-btn-color;
        column-gap: 1.25rem;
        padding-bottom: 1rem;
        padding-top: 1.875rem;
        .nav-link {
            border: none;
            color: $light-gray;
            font-family: $secondary-font-family;
            font-size: 1rem;
            font-weight: $normal;
            padding-left: 0;
            padding-right: 0;
            &:hover {
                border: none;
                color: $tertiary-dark-color;
                transition: all 0.2s linear;
            }
            &.active {
                border-bottom: solid 1px $primary-dark-color;
                color: $tertiary-dark-color;
                font-weight: $ultra-bold;
            }
            + .dropdown-menu {
                li {
                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
                    }
                }
                .nav-link {
                    background-color: #DADAD5;
                    border-radius: 0;
                    color: $black;
                    font-size: 0.75rem;
                    padding: 0.5rem;
                    &:hover {
                        background-color: $primary-dark-color;
                        color: $white;
                    }
                    &.active {
                        border: none;
                    }
                }
            }
        }
    }
    .tab-title-wrapper {
        background-color: $white;
        color: $dark-gray;
        font-family: $primary-font-family;
        margin-bottom: 1.4375rem;
        padding: 1rem;
        .tab-title {
            font-size: 1.25rem;
            font-weight: $ultra-bold;
            line-height: 1.5rem;
            margin-bottom: 0;
        }
        small {
            font-size: 0.8125rem;
            font-weight: $normal;
            line-height: 1rem;
        }
    }
    .demographics-date-filter {
        background-color: $white;
        font-family: $primary-font-family;
        .form-select {
            background-position: right 1.75rem center;
            border: none;
            color: $dark-gray;
            font-size: 1.1rem;
            max-width: 27.9375rem;
            padding: 0;
        }
        .demographics-action-btns {
            .btn {
                font-size: 1.1rem;
                font-weight: $ultra-bold;
                height: 50px;
                padding: 0.7rem 1rem;
                margin-right: 10px;
                width: 50px;
            }
            .btn-new,
            .btn-save {
                background-color: $primary-light-color;
                border-color: $primary-light-color;
                &:hover {
                    background-color: #00acd6;
                }
            }
            .btn-copy {
                background-color: $orange;
                border-color: $orange;
                &:hover {
                    background-color: #e08e0b;
                }
            }
            .btn-delete {
                background-color: #dd4b39;
                border-color: #dd4b39;
                &:hover {
                    background-color: #d73925;
                }
            }
        }
    }
    .checkbox-table-wrapper,
    .ideas-table-wrapper {
        input[type="radio"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            position: relative;
            height: 20px;
            width: 20px;
            outline: none;
            margin: 0 5px 0 0;
            cursor: pointer;
            border: 2px solid $primary-dark-color;
            background: transparent;
            border-radius: 50%;
            display: inline-grid;
            justify-self: end;
            justify-items: center;
            align-items: center;
            overflow: hidden;
            transition: border .5s ease;

            &:focus {
                border-color: transparent !important;
            }
        
            &::before,
            &::after {
                content: "";
                display: flex;
                justify-self: center;
                border-radius: 50%;
            }
        
            &::before {
                position: absolute;
                width: 100%;
                height: 100%;
                background: $white;
                z-index: 1;
                opacity: 1;
            }
        
            &::after {
                position: relative;
                width: calc(100% /2);
                height: calc(100% /2);
                background: $white;
                left: 100%;
                transition: left .5s cubic-bezier(0.48, 1.97, 0.5, 0.63);
            }
        
            &:checked {
                background: $primary-dark-color;
        
                &::after {
                    left: 0%;
                    animation: stretch-animate .3s ease-out .17s;
                }
        
                &::before {
                    opacity: 0;
                }
        
                ~input[type="radio"] {
                    &::after {
                        left: -100%;
                    }
                }
            }
        }
        
        @keyframes stretch-animate {
          0% {
            transform: scale(1,1)
          }
          28% {
            transform: scale(1.15,.85)
          }
          50% {
            transform: scale(.9,1.1)
          }
          100% {
            transform: scale(1,1)
          }
        }
        h2 {
            color: $dark-gray;
            font-family: $primary-font-family;
            font-size: 1.4375rem;
            font-weight: $bold;
            line-height: 1.75rem;
        }
        .add-new-wrapper {
            column-gap: 1.25rem;
            flex-wrap: wrap;
            row-gap: 10px;
            .btn {
                background-color: $secondary-btn-color;
                border-radius: 0.4375rem;
                color: $white;
                font-family: $primary-font-family;
                font-size: 0.8125rem;
                font-weight: $normal;
                line-height: 1rem;
                margin-bottom: 0;
                margin-top: 0;
                padding: 0.45rem 1.65rem;
            }
            .add-settings {
                color: $tertiary-btn-color;
                cursor: pointer;
            }
            .notify {
                background-color: $btn-danger-color;
            }
        }
        .table {
            th,
            td {
                font-weight: $normal;
            }
            th {
                font-size: 1.0625rem;
                line-height: 1.3125rem;
                padding: .75rem .5rem;
            }
            td {
                font-size: 1.125rem;
                line-height: 1.375rem;
                padding: 1rem .5rem;
                .form-check-input[type=checkbox] {
                    border: 1px solid $primary-dark-color;
                    border-radius: 0.1875rem;
                    height: 1rem;
                    margin-top: 0;
                    vertical-align: middle;
                    width: 1rem;
                    &:checked {
                        background-color: $primary-dark-color;
                        font-weight: $light;
                    }
                }
                .fa-grip-vertical {
                    color: $secondary-light-color;
                    cursor: -moz-grab;
                    cursor: -webkit-grab;
                }
                &:first-child {
                    padding-left: 1.5625rem;
                    width: 7.3125rem;
                }
                &:last-child {
                    padding-right: 1.5625rem;
                    text-align: right;
                }
                .fa-pen-to-square {
                    color: $secondary-btn-color;
                }
                button {
                    all: unset;
                    cursor: pointer;
                }
                .disabled {
                    .fa-trash-can {
                        color: rgb(244 67 54 / 45%);
                    }
                }
            }
            .checked-row {
                background-color: $primary-dark-color !important;
                transition: 0.2s all linear;
                .form-check-input[type=checkbox] {
                    border: 1px solid $white;
                }
                .areas {
                    color: $white;
                }
                .fa-trash-can {
                    display: none;
                }
            }
        }
        .react-bootstrap-table {
            table {
                border-bottom: 1px solid $border-color;
            }
            th {
                font-weight: $bold;
                &:first-child {
                    padding-left: 1.5625rem;
                }
                &:last-child {
                    text-align: center;
                }
            }
            td {
                &:first-child {
                    width: auto;
                }
                &:last-child {
                    padding-right: 0.5rem;
                }
                div {
                    align-items: center;
                    column-gap: 15px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
        .react-bootstrap-table-pagination {
            flex-wrap: wrap;
            margin: 0 1rem;
            padding-bottom: 1rem;
            row-gap: 1rem;
            #pageDropDown {
                all: unset;
                border: solid 1px $border-color !important;
                margin-right: 0.5rem;
                padding: 0.25rem 0.5rem;
                font-family: $primary-font-family;
                + .dropdown-menu {
                    bottom: 2rem;
                    li {
                        color: $black;
                        a {
                            color: inherit;
                        }
                    }
                }
            }
            .react-bootstrap-table-pagination-total {
                font-family: $primary-font-family;
            }
            .pagination {
                .active {
                    .page-link {
                        background-color: $primary-dark-color;
                        color: $white;
                    }
                }
                .page-link {
                    color: $primary-dark-color;
                    font-family: $primary-font-family;
                }
            }
        }
    }
    .demographic-metrics-table-wrapper {
        table {
            th,
            td {
                &:first-child {
                    border-right: 1px solid $border-color;
                    padding-left: 0.5rem !important;
                    width: 56px !important;
                }
                &:nth-child(2) {
                    padding-left: 0.9375rem !important;
                    width: 427px !important;
                }
                &:not(:first-child, :nth-child(2)) {
                    width: 154px !important;
                    input {
                        width: 100px !important;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
            th {
                font-weight: $bold !important;
                line-height: 3.0625rem !important;
                text-transform: capitalize;
            }
            td {
                font-weight: $semi-bold !important;
                line-height: 1.0625rem;
                padding-bottom: 0 !important;
                padding-top: 0 !important;
                vertical-align: middle !important;
                &:first-child {
                    font-size: 1rem !important;
                    line-height: 3.75rem !important;
                    text-align: center;
                }
                &:last-child {
                    text-align: unset !important;
                }
            }
        }
    }
    .tier-table-wrapper {
        table {
            thead {
                tr {
                    &:first-child {
                        border-top: none;
                    }
                }
            }
            th,
            td {
                text-align: center;

            }
            th {
                font-weight: $bold;
                line-height: 2.0625rem;
                padding: 1rem 0;
                text-transform: capitalize;
                width: 9rem;
                &:first-child {
                    padding-left: 1rem;
                }
            }
            td {
                font-weight: $semi-bold;
                padding: 1rem 0;
                .btn {
                    border-radius: 0.4375rem;
                    color: $black;
                    height: 2.375rem;
                    text-align: center;
                    width: 8.6875rem;
                }
                input {
                    border: 1px solid $border-color;
                    height: 1.875rem;
                    padding: 0;
                    text-align: center;
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    &[type=number] {
                        -moz-appearance: textfield;
                    }
                    &[data-type="min_value"],
                    &[data-type="max_value"] {
                        width: 4rem;
                        display: inline-block;
                    }
                    &.form-check-input {
                        width: 3.1875rem;
                    }
                    &#minimum,
                    &#maximum {
                        background-color: transparent;
                        border: none;
                        cursor: not-allowed;
                        max-width: fit-content;
                        width: 20%;
                        &:focus,
                        &:focus-visible {
                            box-shadow: none;
                            outline: 0;
                        }
                    }
                }
                .form-check-input {
                    cursor: pointer;
                    height: 1.5rem;
                    vertical-align: middle;
                    &:checked {
                        background-color: $toggle-bg-color;
                        border-color: $toggle-bg-color;
                    }
                }
                span {
                    margin: 0 0.3125rem;
                }
            }
        }
    }
    .client-gap-analysis-table-wrapper {
        th,
        td {
            &:first-child {
                border-right: 1px solid $border-color !important;
                text-align: center;
            }
            &:last-child {
                border-left: 1px solid $border-color !important;
                text-align: center;
            }
        }
        thead {
            tr {
                th {
                    font-size: 1rem;
                    line-height: 1.25rem;
                    padding: 1rem 0.75rem;
                    &:nth-child(2),
                    &:nth-child(8) {
                        text-align: center;
                    }
                    &:nth-child(2) {
                        width: 12rem;
                    }
                }
            }
        }
        tbody {
            td {
                color: $dark-gray;
                font-size: 0.9rem;
                font-weight: $semi-bold;
                padding: 0.75rem;
                // position: relative;
                text-align: center;
                select {
                    background-color: transparent;
                    border: none;
                    color: $tertiary-dark-color;
                    margin: auto;
                    padding-left: 0;
                    width: unset;
                }
                .react-datepicker-wrapper {
                    input {
                        display: block;
                        padding: 0.375rem 0.75rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        -webkit-appearance: none;
                        appearance: none;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    }
                }
                    /* + .fa-calendar {
                        color: $black;
                        position: absolute;
                        right: 25px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                .react-datepicker__tab-loop {
                    + .fa-calendar {
                        color: $black;
                        position: absolute;
                        right: 25px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                } */
            }
        }
    }
    .value-grid-table-wrapper,
    .relational-grid-table-wrapper {
        th,
        td {
            text-align: center;
            textarea {
                border: 0.5px solid $dark-gray;
                border-radius: 0.4375rem;
                color: $dark-gray;
                height: 40px;
                margin: auto;
                max-width: 11.6875rem;
            }
            &:first-child {
                border-right: 1px solid $border-color !important;
                width: 14.375rem;
            }
            &:last-child {
                border-left: 1px solid $border-color !important;
                width: 18.75rem;
            }
        }
        thead {
            tr {
                th {
                    padding-bottom: 0.5rem;
                    padding-top: 1rem;
                    &:first-child,
                    &:last-child {
                        font-size: 1rem;
                        line-height: 2.5rem;
                    }
                    .btn {
                        border-radius: 0.4375rem;
                        color: $black;
                        font-size: 1rem;
                        font-weight: $semi-bold;
                        line-height: 1.15rem;
                        text-align: center;
                        width: 7.5rem;
                    }
                }
            }
        }
        tbody {
            td {
                color: $dark-gray;
                font-size: 1rem;
                font-weight: $semi-bold;
                padding: 0.75rem;
                select {
                    background-color: transparent;
                    border: none;
                    color: $tertiary-dark-color;
                    margin: auto;
                }
                &:not(:first-child) {
                    select {
                        width: unset;
                    }
                }
            }
        }
    }
    .client-scoring-table-wrapper {
        table {
            th,
            td {
                &:first-child {
                    border-right: 1px solid $border-color ;
                    width: 12rem;
                }
                &:nth-child(7) {
                    border-left: 1px solid $border-color;
                    width: 6.25rem;
                }
                &:last-child {
                    width: 11.5625rem;
                }
            }
            thead {
                tr {
                    border-top: none !important;
                    &:first-child {
                        th {
                            font-size: 1.125rem;
                            line-height: 2.5rem;
                            padding-top: 1.5rem;
                            text-align: center;
                            text-transform: capitalize;
                            &:nth-child(3) {
                                border-left: 1px solid $border-color;
                            }
                        }
                    }
                    &:nth-child(2) {
                        th {
                            // color: #7c7c7c;
                            line-height: 1.1875rem;
                            padding: 1rem 0.25rem;
                            text-align: center;
                            width: 120px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    &:not(:first-child) {
                        border-top: none;
                    }
                    &:last-child {
                        border-bottom: 1px solid $border-color;
                    }
                }
                td {
                    font-weight: $semi-bold;
                    line-height: 1.25rem;
                    padding: 0.25rem 0.5rem;
                    text-align: center;
                    select {
                        background-color: transparent;
                        cursor: pointer;
                        font-size: 0.85rem;
                        margin: auto;
                        width: unset;
                        max-width: 3.4375rem;
                    }
                    .btn {
                        border-radius: 0.4375rem;
                        color: $black;
                        width: 7.5rem;
                    }
                    &:first-child {
                        .dlt-client {
                            background-color: transparent;
                            color: $btn-danger-color;
                        }
                    }
                }
            }
        }
        .nav-tabs {
            column-gap: 0.9375rem;
            padding-bottom: 2.375rem;
            padding-top: 3.5rem;
            .nav-link {
                background-color: $white;
                border-radius: 50%;
                box-shadow: 0px 3px 6px $box-shadow;
                color: #C7C8C9;
                font-size: 0.875rem;
                padding: 0.45rem 0.83rem;
                &.active {
                    border: 1px solid $secondary-dark-color;
                    color: $primary-dark-color;
                    font-size: 1rem;
                    padding: 0.6rem 1.1rem;
                }
            }
        }
    }
    .wow-tracker-table-wrapper {
        .tracker-filter {
            span,
            div {
                font-family: $primary-font-family;
            }
        }
        th,
        td {
            &:not(:last-child) {
                border-right: 1px solid $border-color !important;
            }
            width: 10rem;
            // &:first-child,
            // &:nth-child(2),
            // &:nth-child(3) {
            //     background-color: #fff;
            //     box-shadow: inset -1px 0 0 $border-color;
            //     position: sticky;
            // }
            // &:first-child {
            //     left: 0;
            // }
            // &:nth-child(2) {
            //     left: 88px;
            // }
            // &:nth-child(3) {
            //     left: 180px;
            // }
        }
        thead {
            tr {
                th {
                    line-height: 1.25rem;
                    padding: 1rem;
                    text-align: center;
                }
            }
        }
        tbody {
            td {
                color: $dark-gray;
                font-size: 0.9rem;
                font-weight: $semi-bold;
                padding: 0.5rem 1rem;
                &:not(:nth-child(3)) {
                    text-align: center;
                }
                select {
                    background-color: transparent;
                    border: none;
                    color: $dark-gray;
                    font-size: 1rem;
                    width: unset;
                    option {
                        padding-bottom: 0.25rem;
                    }
                }
            }
        }
    }
}