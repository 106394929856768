/* Header */
/* Header Top */
.header-top {
    background-color: rgba($white, 0.95);
    box-shadow: 0 10px 15px -3px rgba($black, .1), 0 4px 6px -4px rgba($black, .1);
    position: relative;
    .brand-logo {
        img {
            max-width: 200px;
        }
    }

    ul {
        margin-bottom: 0;
        padding: 0.9375rem 0;
        gap: 0.625rem;
        flex-wrap: wrap;

        li {
            color: $light-gray;
            font-family: $primary-font-family;
            font-size: 0.875rem;

            a {
                color: $primary-dark-color;
            }

            &:first-child {
                a {
                    &:not(.btn) {
                        &:hover {
                            color: $orange;
                        }
                    }
                }
            }

            img {
                border-radius: 50%;
                object-fit: cover;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    p {
        color: $light-gray;
        font-size: 0.875rem;
        margin-bottom: 0;
    }

    .profile-dropdown {

        // a {
        //     &:hover {
        //         color: $white;
        //     }
        // }
        .user-avatar {
            font-size: 0.815rem;

            img {
                height: 1.5625rem;
                width: 1.5625rem;
            }
        }

        .dropdown-menu {
            left: auto !important;
            right: 0 !important;
            width: 17.5rem;
            overflow: hidden;
            border: none;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
            .user-header {
                background-color: #3c8dbc;
                height: 10.9375rem;
                padding: 0.625rem;
                text-align: center;

                p {
                    color: hsla(0, 0%, 100%, .8);
                    font-size: 1.0625rem;
                    margin-top: 0.625rem;

                    small {
                        font-size: 0.75rem;
                    }
                }
            }

            .user-footer {
                background-color: #f9f9f9;
                padding: 0.625rem;

                .btn {
                    background-color: #f4f4f4;
                    border-color: #ddd;
                    color: #666;
                }
            }

            img {
                border: 0.1875rem solid;
                border-color: hsla(0, 0%, 100%, .2);
                height: 5.625rem;
                width: 5.625rem;
            }
        }
    }
}

.header-bottom {
    background-color: $page-bg;
    border-bottom: solid 1px $primary-dark-color;
    padding-top: 2rem;

    .performance {
        align-items: center;
        display: inline-flex;
        padding: 0 2rem;

        &:first-child {
            border-right: 1px solid #555555;
        }

        &__content {
            padding: 0 .9rem;

            &__health {
                .percent-number {
                    display: inline-block;
                    text-align: center;
                    font-family: $primary-font-family;
                    font-size: 18px;
                    line-height: 22px;
                    margin-left: 2px;
                }
            }
        }

        &__actions {
            .badge--chart {
                font-family: $primary-font-family;
                font-size: 17px;
                margin-left: 5px;
                position: relative;
                z-index: 10;
            }
        }

        &__title {
            color: #797A76;
            font-family: $primary-font-family;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    .performance__health {
        font-family: $primary-font-family;
        font-weight: 600;
    }

    .performance__health--status {
        color: #939491;
        font-family: $primary-font-family;
        font-weight: bold;
    }

    .performance__actions {
        margin-top: .5rem;
        font-family: $primary-font-family;
        color: #939491;
    }

    .avatar-table {
        width: 60px;
    }

    .health--f {
        .performance__health {
            color: #E4403E;
            font-family: $primary-font-family;
        }

        .badge--grade {
            color: #ffffff;
            background: #E4403E;
            font-family: $primary-font-family;
        }
    }

    .health--b {
        .performance__health {
            color: #0E9DA5;
            font-family: $primary-font-family;
        }

        .badge--grade {
            color: #ffffff;
            background: #0E9DA5;
            font-family: $primary-font-family;
        }
    }

    .badge--default {
        font-size: 10px;
        border-radius: 50%;
        min-width: 25px;
        height: 25px;
        display: inline-block;
        text-align: center;
        line-height: 25px;
        background-color: #007dc5;
        color: #ffffff;
        margin-right: 5px;
        font-family: $primary-font-family;
    }

    .badge--grade {
        font-weight: 700;
        margin-left: 6px;
        text-transform: uppercase;
        font-family: $primary-font-family;
    }

    .navbar-toggler {
        &:focus {
            box-shadow: none;
        }
    }

    .navbar-nav {
        .nav-link {
            cursor: pointer;
            font-size: 0.8125rem;
            padding: 0.6875rem 0.9375rem !important;
            border-radius: 5px 5px 0 0;
            background-color: #94a3b8 !important;
            &:hover {
                background-color: $primary-dark-color !important;
            }
            &.active {
                background-color: $primary-dark-color;
                color: $white !important;

                &:hover {
                    background-color: $primary-dark-color;
                }
            }
        }
    }

    .menu-left {
        .nav-link {
            // background-color: #797a76;
            font-family: $primary-font-family;

            &:hover {
                background-color: $primary-dark-color;
            }
        }
    }

    .menu-right {
        .nav-link {
            // background-color: #DADAD5;
            color: #3d3d3d;
            font-family: $primary-font-family;
        }
        &:nth-child(2) {
            .nav-link {
                background-color: $white !important;

                &:hover,
                &.active {
                    color: $white;
                    background-color: $primary-dark-color !important;
                }
            }
        }

        .dropdown {
            &:hover {
                .dropdown-menu {
                    animation: moveUp 0.2s linear;
                }
            }
        }

        .dropdown-menu {
            border-radius: 6px !important;
            overflow: hidden;
            border: none;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
            &.show {
                animation: moveUp 0.2s linear;
            }

            li {
                @extend .nav-link;
                font-family: $primary-font-family;
                font-size: 0.8125rem;
                padding: 0;
                text-align: center;

                .dropdown-item {
                    color: #3d3d3d;
                    cursor: pointer;
                    padding: 0.6875rem 0;

                    &:hover,
                    &:focus,
                    &:active,
                    &.active {
                        background-color: $primary-dark-color;
                        color: $white;
                    }
                }

                &.active {
                    background-color: $primary-dark-color;

                    &:hover {
                        background-color: $primary-dark-color;
                    }

                    .dropdown-item {
                        color: $white;
                    }
                }
            }
        }
    }
}