/* Global CSS */
body {
    font-family: sans-serif;
    font-size: 16px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }

    a {
        text-decoration: none !important;
    }

    ul,
    ol {
        list-style: none;
        margin: 0;
        padding: 0;
        padding-inline-start: 0;
    }
}

.container {
    max-width: 1200px;
}

._mr10 {
    margin: 0px 10px 0px 0px!important;
}
.pull-right {
    float: right !important;
}

:focus, .btn:focus {
    border: dashed 1px $black !important;
    box-shadow: none !important;
}

/* Header */
/* Header Top */
// .wow-header {
//     .dropdown {
//         .dropdown-toggle {
//             &::after {
//                 display: none;
//             }
//         }
//         &:hover {
//             .dropdown-menu {
//                 display: block;
//             }
//         }
//     }
// }

main {
    background-color: $page-bg;
    min-height: calc(100vh - 250px);
    .wow-app {
        .btn-default {
            color: #333;
            background-color: #fff;
            border-color: #ccc;
        }
        .btn {
            display: inline-block;
            margin-bottom: 0;
            font-weight: bold;
            text-align: center;
            vertical-align: middle;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            cursor: pointer;
            background-image: none;
            border: 1px solid transparent;
            white-space: nowrap;
            padding: 6px 12px;
            font-size: 16px;
            line-height: 1.42857143;
            border-radius: 4px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            margin-top: 5px;
            margin-bottom: 12px;
            &:hover {
                opacity: 0.8;
            }
        }
        .btn-success {
            color: #fff;
            background-color: #5cb85c;
            border-color: #4cae4c;
        }
        input, button, select, textarea {
            font-family: $primary-font-family;
            font-size: inherit;
        }
        button, html input[type="button"], input[type="reset"], input[type="submit"] {
            -webkit-appearance: button;
            cursor: pointer;
        }
        .table > :not(:first-child) {
            border-top: 0px!important;
        }
        .react-bs-table-container {
            padding: 1rem;
        }
        .react-bs-table-tool-bar {
            margin-bottom: 1rem;
        }
        .react-bs-table {
            .table-bordered {
                border: 0 !important;
                margin-bottom: 0;
                outline: none !important;
            }
            .react-bs-container-body {
                border-bottom: solid 1px $border-color !important;
            }
            .table {
                th,
                td {
                    border-right: solid 1px $border-color !important;
                    &:first-child {
                        border-left: solid 1px $border-color !important;
                    }
                }
                th {
                    font-weight: $bold;
                }
                td {
                    &:last-child {
                        text-align: center;
                    }
                }
            }
        }
        .tab-content {
            padding: 1.875rem 1.875rem 4.625rem;
        }
        .table-wrapper {
            background-color: $white;
            border: 1px solid $border-color;
            box-shadow: 0px 3px 16px $box-shadow;
            .table {
                width: 78.61rem;
                tr {
                    border-bottom: none;
                    border-top: 1px solid $border-color;
                }
                th {
                    font-size: 1rem;
                    font-weight: $bold;
                }
                td {
                    color: $dark-gray;
                    font-size: 1.125rem;
                    text-transform: capitalize;
                    select,
                    textarea {
                        &:disabled {
                            color: #a6a7aa;
                            cursor: not-allowed;
                        }
                    }
                    select {
                        text-transform: capitalize;
                    }
                    .fa-trash-can {
                        color: $btn-danger-color;
                    }
                }
                th,
                td {
                    border: none;
                    font-family: $primary-font-family;
                    vertical-align: middle;
                    .platinum-btn {
                        background-color: $platinum-color;
                        cursor: default;
                    }
                    .gold-btn {
                        background-color: $gold-color;
                        cursor: default;
                    }
                    .silver-btn {
                        background-color: $silver-color;
                        cursor: default;
                    }
                    .bronze-btn {
                        background-color: $bronze-color;
                        cursor: default;
                    }
                    textarea {
                        resize: none;
                    }
                    .visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
                        position: unset !important;
                    }
                }
                thead {
                    background-color: $table-head-bg;
                    box-shadow: 0 7px 10px 0 rgb(0 0 0 / 30%);
                    th {
                        color: $white;
                    }
                }
                tbody {
                    tr {
                        &:nth-child(even) {
                            background: rgb(238, 238, 238);
                        }
                    }
                }
            }
        }
        hr {
            margin: 0;
        }
        .table-responsive {
            -webkit-overflow-scrolling: auto;
        }
    }
}

.disabled_table {
    background-color: rgba(255, 255, 255, 0.3);
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 11;
}