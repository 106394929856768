$white: #fff;
$black: #000;
$orange: #f39c12;
$dark-gray: #707070;
$light-gray: #797A76;

$primary-dark-color: #007DC5;
$secondary-dark-color: #337DBF;
$tertiary-dark-color: #174A84;

$primary-light-color: #00C0EF;
$secondary-light-color: #A5C9FF;

$table-head-bg: #4c4878;

$primary-btn-color: #F49C12;
$secondary-btn-color: #06A55A;
$tertiary-btn-color: #DADAD4;
$btn-danger-color: #f44336;
$btn-warning-color: #ffc107;

$page-bg: #ECF0F4;

$border-color: #D3D6DD;

$box-shadow: #0A4B6D14;

// Table buttons colors
$platinum-color: #FCB40B;
$gold-color: #FFD779;
$silver-color: #D8D8D8;
$bronze-color: #B4A37E;
$toggle-bg-color: #4E9D00;