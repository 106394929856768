// Add Form button modal
.item-detail__container {
    position: fixed;
    width: 100%;
    max-width: 900px;
    right: 0;
    top: 0;
    background: #ffffff;
    height: 100vh;
    overflow-y: auto;
    transition: .3s ease-in-out;
    z-index: 10000;
    &.show {
        max-width: 1000px;
        -webkit-animation: slidePanel 0.4s;
        animation: slidePanel 0.4s;
        box-shadow: -5px 0 25px rgb(0 0 0 / 20%);
    }
    .item-detail {
        padding: 25px;
        .item-detail__header {
            padding: 15px;
            margin-top: -25px;
            margin-left: -25px;
            margin-right: -25px;
            background-color: #eff1f4;
            margin-bottom: 10px;
            -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
            box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
            h3 {
                color: $dark-gray;
                display: inline-block;
                font-size: 1.5rem;
            }
        }
        .item-detail__heading {
            label {
                margin-bottom: 5px;
                font-weight: 700;
                text-align: left !important;
                padding: 0px 0px 5px 0px !important;
                color: #444;
                font-size: 13px;
            }
            input,
            select {
                border: 1px solid $border-color;
                color: $dark-gray;
                margin-bottom: 1rem;
            }
        }
    }
}

@keyframes slidePanel {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

// Delete modal
.confirm-box {
    .confirm-box__content {
        border-radius: 0.5rem;
        left: 50%;
        padding: 2rem;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        span {
            font-family: $primary-font-family;
        }
        button {
            border: none;
            border-radius: 3px;
            padding: 0.25rem 0.75rem;
            transition: all 0.3s linear;
        }
        button[role="confirmable-button"] {
            background-color: $orange;
            border: 1px solid $orange;
            color: $white;
            &:hover {
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            }
        }
        button[role="cancellable-button"] {
            background-color: $white;
            border: 1px solid #DADAD5;
            color: $dark-gray;
            &:hover {
                background-color: #EFEFEF;
                color: $black;
            }
        }
    }
}