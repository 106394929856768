// TO animate dropdwon menu
@keyframes moveUp {
    from {
        opacity: 0;
        top: 120%;
    }
    to {
        opacity: 1;
        top: 100%;
    }
}
