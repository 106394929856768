// Media Queries
.header {
    @media screen and (max-width: ($device-lg-size - 1)) {
        &-wrapper {
            position: relative;

            .header-top {
                box-shadow: none;
                flex: 1;
            }
    
            .header-bottom {
                background-color: $white;
                border-bottom: unset;
                padding-top: 0;
    
                #wowMenu {
                    position: absolute;
                    width: 100%;
                    z-index: 99;
                    top: 65px;
                    left: 0;
                    background-color: $white;
                    padding: 0 1rem 1rem;
                }
                .navbar-nav {
                    .nav-link {
                        border-radius: 0;
                        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                        margin-bottom: 0.25rem;
                    }
                }
            }
        }
    }
}
@media (max-width: ($small-laptop-size - 1)) {
    .wow-app {
        .checkbox-table-head {
            row-gap: 1rem;
            h2 {
                font-size: 1.15rem;
            }
        }
    }
}
@media (max-width: ($tablet-size + 91)) {
    .wow-app {
        #wowTab{
            display: block !important;
            overflow-x: scroll;
            white-space: nowrap;
            .nav-item {
                display: inline-block;
                margin-right: 20px;
            }
        }
    }
}

@media (min-width: $device-lg-size) and (max-width: ($small-laptop-size + 199)) {
    .header-bottom {
        .navbar-nav {
            .nav-link {
                font-size: 0.7rem;
                padding: 0.6875rem 0.45rem !important;
            }
        }
        .menu-right {
            .dropdown-menu {
                li {
                    font-size: 0.75rem;
                    padding: 0.25rem 0;
                }
            }
        }
    }
}

@media (min-width: $device-lg-size) {
    .header-bottom {
        .navbar-nav {
            .nav-link {
                text-align: center;
                &:not(:last-child) {
                    margin-right: 6px;
                }
            }
        }
        .menu-right {
            .dropdown {
                &:hover {
                    .dropdown-menu {
                        animation: moveUp 0.2s linear;
                    }
                }
            }
        }
    }
    .wow-app {
        .checkbox-table-wrapper {
            .react-bootstrap-table {
                th {
                    &:last-child {
                        width: 10rem;
                    }
                }
            }
        }
    }
}

@media (max-width: ($device-lg-size - 1)) {
    .header-bottom {
        .navbar-nav {
            .nav-link {
                margin-top: 0.3125rem;
                text-align: left;
            }
        }
        .menu-left {
            border-top: 1px solid $border-color;
        }
        .menu-right {
            padding-top: 0.3125rem;
            .dropdown-menu {
                li {
                    margin-top: 0.3125rem;
                    text-align: left;
                    .dropdown-item {
                        cursor: pointer;
                        padding-left: 1.5rem !important;
                    }
                }
            }
        }
    }
    .wow-app {
        .ideas-table-wrapper,
        .demographic-metrics-table-wrapper {
            .table {
                width: 41.1rem !important;
            }
        }
        .value-grid-table-wrapper,
        .wow-tracker-table-wrapper,
        .tier-table-wrapper {
            .table {
                width: 60rem !important;
            }
        }
        .demographics-date-filter {
            row-gap: 1rem;
            .form-select {
                font-size: 1.15rem;
            }
            .btn {
                font-size: 1rem !important;
            }
        }
        .table-wrapper {
            .table {
                th {
                    font-size: 1rem !important;
                    line-height: 1.25rem !important;
                }
                td {
                    font-size: 0.875rem;
                    line-height: 1.1;
                }
            }
        }
        .checkbox-table-wrapper {
            .table {
                width: 100% !important;
                .areas {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: ($small-tablet-size - 1)) {
    .header-bottom {
        .performance {
            display: flex;
            justify-content: space-between;
            padding: 0 0 1rem;
            &:first-child {
                border-right: none;
            }
            &:not(:first-child) {
                border-top: 1px solid #555555;
                padding-top: 1rem;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    // Add Form button modal
    .item-detail__container {
        .item-detail {
            .item-detail__header {
                h3 {
                    display: block !important;
                    margin-bottom: 10px;
                    text-align: center;
                }
                .btn-success {
                    float: left !important;
                }
            }
        }
    }
}