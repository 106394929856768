// Font Weights
$ultra-thin: 100;
$ultra-light: 200;
$light: 300;
$regular: 400;
$normal: 500;
$semi-bold: 600;
$bold: 700;
$bolder: 800;
$ultra-bold: 900;

// Font Family
$primary-font-family: sans-serif;
$secondary-font-family: sans-serif;