// Variables
@import 'variables/colors';
@import 'variables/typography';
@import 'variables/screens';

// Components
@import 'components/footer';
@import 'components/global';
@import 'components/header';
@import 'components/tables';
@import 'components/responsive';
@import 'components/modals';

//Animation
@import 'animation/animate';

.popup-overlay .popup-content {
    background-color: #292d3e!important;
    border: 1px solid #464545!important;
    color: #fff;
}
.bb-color{border-bottom:1px solid #D3D6DD}
@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
.popup-content {
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    -moz-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    -o-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    max-width: 100%;
    width: unset !important;
  }
#popup-root .modal {
    font-size: 12px;
    display: block;
    position: inherit;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    width: 100%;
    padding: 10px 5px;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    right: 0px;
    top: 0px;
    background: #ffffff;
    border-radius: 50%;
    border: 1px solid #cfcece;
  }
  .popup-content .button {
    background-color: #fff;
    border: 3px dashed #25c2a0;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-family: Roboto,Arial,sans-serif;
    font-size: 14px;
    letter-spacing: 1.5px;
    line-height: 2em;
    margin: 10px 4px;
    outline: 0;
    padding: 0 30px;
    position: relative;
    text-transform: uppercase;
    transition: .3s;
}
#popup-root .actions {
    margin: auto!important;
    text-align: right!important;
}
#popup-root .actions, #popup-root .content {
    padding: 20px!important;
    width: 100%!important;
}
.popup-content label{font-size: 1rem;}
.popup-content input { margin-top: 10px;}

.custom-select-style{ border: none!important; text-align: center;}



.dropdown-item{text-align: left!important; padding: .8rem 1rem!important;}
main .wow-app .table-wrapper .table tr:hover .custom-select-style{ background-color: #ECECEC!important;}

.date-modal{
  overflow:unset !important;
}
.form-select{
  text-wrap: wrap;
}